import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  Row,
  Col,
  Input
} from "reactstrap";

import HangtagsComponent from "./HangtagsTabComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { Box } from '@mui/system';
import PaginationComponent from "~/components/PaginationComponent";
import { getVarFromUrl } from "../../../helpers/utils";

@inject("HangtagStore", "DeviceStore", "PageStore")
class HangtagsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hangtags: [],
      searchText:""
    };
  }

  async componentDidMount() {
    this.getHangtags();
  }

  getHangtags = async (filter, skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0;
    if (filter === undefined) filter = "";

    const { HangtagStore } = this.props;
    const deviceId = getVarFromUrl("deviceId");
    const pageId = getVarFromUrl("pageId");
    const data = await HangtagStore.getHangtagsByPage(deviceId, pageId, filter, skip);
    let hangtags = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.tags.length > 0) { 
      hangtags = data.tags;
      countItems = data.countItems;
      countPages = data.countPages;
    }

    this.setState({
      hangtags,
      currentPage,
      countItems,
      countPages,
      isLoading: false,
      deviceId,
      pageId
    });
  };

  findProducts = async (value) => {
    this.setState({
      searchText: value,
    });
    const { skip } = this.state;
    this.getHangtags(value, skip);
  };

  onClick = (hangtagId) => {
    const { history } = this.props;
    history.push(`hangtag?deviceId=${getVarFromUrl("deviceId")}&pageId=${getVarFromUrl("pageId")}&hangtagId=${hangtagId}`);
  };

  onDelete = async (hangtagId) => {
    this.setState({
      isLoading: true
    });

    const { HangtagStore } = this.props;

    const result = await HangtagStore.delete(hangtagId);
    if (result === "true" || result === true){
      AlertComponent.success("Etiqueta removida com sucesso");
      this.getHangtags();
    } else {
      AlertComponent.error("Falha ao remover etiqueta");
    }

    this.setState({
      isLoading: false
    });
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { HangtagStore } = this.props;
    const { hangtags } = this.state;
    const items = Array.from(hangtags);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    HangtagStore.reorder(items);

    this.setState({
      hangtags: items
    });
  }

  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 10;
    const { searchText } = this.state;
    await this.getHangtags(searchText, skip);
    this.setState({
      currentPage: currentPage
    });
    this.setState({
      isLoading: false
    });
  };

  render() {
    const { isLoading, hangtags, searchText, currentPage, countItems } = this.state;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>      
            <br/>     
            <Row>
              <Col  xs={12} md={8}>
                <Input
                  name="code"
                  type="text"
                  placeholder="Parte do código ou parte do nome do produto"
                  value={searchText}
                  onChange={(e) => this.findProducts(e.target.value)}
                />
              </Col>
              <Col  xs={12} md={4} style={{textAlign: "right"}}>
                <Button
                  className="btn-fill btn-xs"
                  color="primary"
                  type="submit"
                  onClick={()=>this.onClick(0)}
                >
                  Nova etiqueta
                </Button>
              </Col>      
            </Row>
            <br/>
            <Row>
              <Col xs={4} md={2}>
                Código
              </Col>
              <Col xs={4} md={5}>
                Produto
              </Col>
              <Col md={1}>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>Promoção</Box>
              </Col>        
              <Col md={1}>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>Valor</Box> 
              </Col>
            </Row>
            {(hangtags && (
              <HangtagsComponent
                hangtags={hangtags}
                onClick={this.onClick}
                onDelete={this.onDelete}
                handleOnDragEnd={this.handleOnDragEnd}
              />)
            )}
            <PaginationComponent
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={countItems}
              onPageChange={this.onPageChange}
            />
          </>
          
        )}
      </>
    );
  }
}

export default HangtagsContainer;