import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import {
  Row,
  Col
} from "reactstrap";

const PaginationComponent = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 10,
    className
  } = props;

  
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  if (paginationRange === undefined) return <></>;
  
  let lastPage = paginationRange[paginationRange.length - 1];
  return (    
    <div>
      <Row>
        <Col xs={6} md={6} style={{textAlign: "left", paddingTop: "5px"}}>
          <span > Total de items: {totalCount} </span> 
        </Col>
        <Col xs={6} md={6} style={{textAlign: "right"}}>
          <ul
            className={classnames('pagination-container', { [className]: className })}
          >
            <li
              key={"First"}
              className={classnames('pagination-item', {
                disabled: currentPage === 1
              })}
              onClick={onPrevious}
            >
              <div className="arrow left" />
            </li>
            {paginationRange.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <li key={"DOTS"} className="pagination-item dots">&#8230;</li>;
              }
              return (
                <li
                  key={pageNumber}
                  className={classnames('pagination-item', {
                    selected: pageNumber === currentPage
                  })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </li>
              );
            })}
            <li
              key={"Last"}
              className={classnames('pagination-item', {
                disabled: currentPage === lastPage
              })}
              onClick={onNext}
            >
              <div className="arrow right" />            
            </li>
          </ul>
        </Col>         
      </Row>
    </div>
  );
};

export default PaginationComponent;