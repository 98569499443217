export const URL_AUTH_BASE_PATH = process.env.REACT_APP_AUTH_URL;

export const URL_APP_BASE_PATH = process.env.REACT_APP_URL;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const URL_BRASIL_API_PATH = process.env.REACT_APP_URL_BRASIL_API;

export const URL_APP_APP_BASE_PATH = process.env.REACT_APP_APP_URL;

export const TOKEN_KEY = "@eprice-web";
export const MENUS = "@eprice-web-menus";
export const RECORD = "@eprice-web-record";

export const DEVICE_INFO_TAB = 1;
export const PAGES_TAB = 2;

export const PAGES_PAGE_TAB = 1;
export const PAGES_HANGTAGS_TAB = 2;
export const PAGES_IMAGES_TAB = 3;

export const ORGANIZATION_TAB = 1;
export const USERS_TAB = 2;
export const SUBSCRIPTION_TAB = 3;

export const ENVIRONMENT_TYPE = {
  qa: "QA",
  production: "PRODUCTION",
};

export const EXPIRE_CAHCE = 60000;
export const FILE_UPLOAD_MAX_LENGTH  = 20000000;

export const IMAGE_TYPE = {
  image: 1,
  video: 2,
  folder: 3,
  notice: 4
};

export const FILE_CONTAINER = {
  designs: "designs",
  images: "images"
}

export const SHOW_TIME_MIDIA_DEFAULT = 10;
export const SHOW_TIME_MIDIA_MIN = 5;
export const TAGS_COUNT_DEFAULT = 50;
export const CHANGE_TIME_PAGE_DEFAULT = 10;
export const PAGE_COLOR_DEFAULT = "#ffffff";

export const PAGE_TYPE = {
  tags: 1,
  list: 2,
  table: 3,
  midia: 4
}

export const USER_LEVEL = {
  establishment: 1,
  resale: 2,
  manager: 3
}

export const PAGE_FREQUENCY = {
  fixed: 1,
  dayOfWeek: 2,
  period: 3
}

export const DAYS_OF_WEEK = [
  { id: 0, title: 'Domingo', },
  { id: 1, title: 'Segunda-Feira' },
  { id: 2, title: 'Terça-Feira' },
  { id: 3, title: 'Quarta-Feira' },
  { id: 4, title: 'Quinta-Feira' },
  { id: 5, title: 'Sexta-Feira' },
  { id: 6, title: 'Sábado' }
]

export const ORGANIZATION_STATUS = {
  inactive: 0,
  active: 1
}

export const USER_STATUS = {
  inactive: 0,
  active: 1
}

export const FILE_STATUS = {
  waiting: 0,
  pending: 1,
  processed: 2,
  error: 3
}

export const CHARGE_STATUS = {
  pending: 1,
  received: 2,
  overdue: 3
}