import React from "react";
import PropTypes from "prop-types";
import { USER_LEVEL } from "../../configs/constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";

const DeviceForm = ({
  onChange,
  goUpdate,
  onCancel,
  device,
  onChangeTagCount,
  onKeyUpOrganization,
  onChangeOrganization,
  organizations,
  organization,
  userLevel
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Dispositivo</h5>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                goUpdate();
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Token</span>
                    <Input
                      id="token"
                      name="token"
                      value={device?.token}
                      placeholder="Token"
                      type="text"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <span>Título</span>
                    <Input
                      name="title"
                      type="text"
                      placeholder="Título"
                      value={device?.title}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {userLevel !== USER_LEVEL.establishment ?
              <Row>    
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span style={{width: "100%"}}>Estabelecimento</span>
                    <Autocomplete
                      noOptionsText={"Nenhum item"}
                      onKeyUp={(event, value) =>
                        onKeyUpOrganization(document.getElementById("organization").value)
                      }            
                      onChange={(event, value) =>                         
                        onChangeOrganization(value)
                      }
                      style={{paddingBottom: "10px"}}                                      
                      value={organization?.name}
                      id="organization"
                      options={organizations.map((organization) => organization.name)}
                      isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                      size="small"
                      renderInput={(params) => (
                        <TextField  {...params} variant='standard'/>
                      )}
                      sx={autoCompleteSx}
                      renderOption={(props, option, state) => {
                        return (                            
                             <span id="ss"  {...props}>
                            {option}
                          </span>                          
                        );
                      }}                      
                    />
                  </FormGroup>                 
                </Col>                
              </Row>          
              : (
              <Row>                
                <Col xs={12} md={12}>
                <FormGroup>
                    <span>Estabelecimento</span>
                    <Input
                      name="organization"
                      type="text"
                      placeholder="Nenhum"
                      value={organization?.name}
                    />
                  </FormGroup>
                </Col>
              </Row>)}      
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <span>Número total de etiquetas apresentadas</span>
                    <Input
                      name="tagCount"
                      type="number"
                      placeholder="Etiquetas"
                      className="form-control"
                      value={device?.tagCount}
                      onChange={(e) => onChangeTagCount(e)}
                    />
                  </FormGroup>
                </Col>                
              </Row>         
              <Row>
                <Col style={{textAlign: "right"}}>
                  {onCancel && 
                    (<Button
                      className="btn-fill btn-xs"
                      color="danger"
                      type="submit"
                      onClick={()=>onCancel()}
                    >
                      Voltar
                    </Button>
                  )}
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>                  
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

DeviceForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  goUpdate: PropTypes.func.isRequired
};

export default DeviceForm;
