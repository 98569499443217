import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  Row,
  Col,
} from "reactstrap";

import PagesComponent from "./PagesTabComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import PaginationComponent from "~/components/PaginationComponent";
import { PAGES_PAGE_TAB } from "../../../configs/constants";
import { getVarFromUrl } from "../../../helpers/utils";

@inject("PageStore", "DeviceStore")
class PagesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pages: [],
    };
  }

  async componentDidMount() {
    this.getPages();
  }

  getPages = async (skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0;

    const { PageStore } = this.props;
    const deviceId = getVarFromUrl("deviceId");
    const data = await PageStore.getPages(deviceId,skip);
    let pagesAux = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.pages.length > 0) {
      pagesAux = data.pages;
      countItems = data.countItems;
      countPages = data.countPages;
    }  
    this.setState({
      pages: pagesAux,
      currentPage: currentPage,
      countItems: countItems,
      countPages: countPages,
      skip: skip,
      isLoading: false,
    });
  };

  onClick = (pageId) => {
    const { history, PageStore } = this.props;
    PageStore.setPageId(pageId);
    history.push(`page?deviceId=${getVarFromUrl("deviceId")}&pageId=${pageId}&tab=${PAGES_PAGE_TAB}`);
  };

  onDelete = async (pageId) => {
    this.setState({
      isLoading: true
    });

    const { PageStore } = this.props;

    const result = await PageStore.delete(pageId);
    if (result === "true" || result === true){
      AlertComponent.success("Página removida com sucesso");
      this.getPages();
    } else {
      AlertComponent.error("Falha ao remover página");
    }

    this.setState({
      isLoading: false
    });
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { PageStore } = this.props;
    const { pages } = this.state;
    const items = Array.from(pages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    PageStore.reorder(items);

    this.setState({
      pages: items
    });
  };

  onPageChange = async (currentPage) => {  
    const skip = (currentPage - 1) * 10;
    await this.getPages(skip);
    this.setState({
      currentPage: currentPage,
      skip: skip
    });
  };

  render() {
    const { isLoading, pages, currentPage, countItems } = this.state;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>
            <Row>
              <Col style={{textAlign: "right"}}>
                <Button
                  className="btn-fill btn-xs"
                  color="primary"
                  type="submit"
                  onClick={()=>this.onClick(0)}
                >
                  Nova página
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={1}>
                Código
              </Col>
              <Col xs={6} md={4}>
                Descrição
              </Col>
              <Col md={3} className="display-none-xs">
                Tipo
              </Col>
              <Col md={2} className="display-none-xs">
                Items
              </Col>
            </Row>   
            {(pages && (
              <PagesComponent
                pages={pages}
                onClick={this.onClick}
                onDelete={this.onDelete}
                handleOnDragEnd={this.handleOnDragEnd}
              />)
            )}
            {(pages && (
              <PaginationComponent
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={countItems}
                onPageChange={this.onPageChange}
                pageSize={countItems}
              />)
            )}
          </>
          
        )}
      </>
    );
  }
}

export default PagesContainer;
