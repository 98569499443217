import React from "react";
import { inject } from "mobx-react";
import {
  Input,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

import AssignDeviceComponent from "./AssignDeviceComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'
import PaginationComponent from "../../components/PaginationComponent";

@inject("DeviceStore", "OrganizationStore")
class AssignDeviceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      devices: [],
      activationCode: "",
    };
  }

  async componentDidMount() {
    this.getDevices();
  }

  getDevices = async (organization, organizationName, skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0;
    const { DeviceStore } = this.props;
    let organizations = await this.getOrganizations(organizationName);
    if (organization === undefined) organization = organizations[0];
    const data = await DeviceStore.getDevices(organization.organizationId,skip);
    let devicesAux = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.devices.length > 0) {
      devicesAux = data.devices;
      countItems = data.countItems;
      countPages = data.countPages;
    }  
    this.setState({
      organization: organization,
      organizations: organizations,
      devices: devicesAux,
      isLoading: false,
      currentPage: currentPage,
      countItems: countItems,
      countPages: countPages,
      skip: skip
    });
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value);
    return organizationsData.organizations;
  };

  onChangeActivationCode = (e) => {
    this.setState({
      activationCode: e.target.value,
    });
  };

  goAssign = async (deviceId) => {
    const { activationCode } = this.state;
    const { DeviceStore } = this.props;

    const result = await DeviceStore.assign(deviceId, activationCode);
    if (result === "true" || result === true){
      AlertComponent.success("Dispositivo associado com sucesso");
    } else {
      AlertComponent.error("Falha ao associar dispositivo");
    }

    this.setState({
      isLoading: false,
      activationCode: "",
    });
  };

  onKeyUpOrganization = async (value) => {
    try {
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = (organizationName) => {
    let { organizations, organization, skip } = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }

    this.getDevices(organization, organizationName, skip);
  };

  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 10;
    const { organization } = this.state;
    await this.getDevices(organization, organization.name, skip);
    this.setState({
      currentPage: currentPage
    });
    this.setState({
      isLoading: false
    });
  };

  render() {
    const { isLoading, devices, activationCode, organization, organizations, currentPage, countItems } = this.state;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>
            <div className="content content-xs">            
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span style={{marginBottom: "0px"}}>Estabelecimento:</span>
                    <Autocomplete
                      noOptionsText={"Nenhum item"}  
                      onKeyUp={(event, value) =>   
                        this.onKeyUpOrganization(document.getElementById("organization").value)
                      }                            
                      onChange={(event, value) =>                         
                        this.onChangeOrganization(value)
                      }
                      style={{paddingBottom: "10px"}}                                      
                      value={organization?.name}
                      id="organization"
                      options={organizations.map((organization) => organization.name)}
                      isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                      size="small"
                      renderInput={(params) => (
                        <TextField  {...params} variant='standard'/>
                      )}
                      sx={autoCompleteSx}
                      renderOption={(props, option) => {
                        return (
                          <span  {...props}>
                            {option}
                          </span>
                        );
                      }}                      
                    />
                    </FormGroup>
                  </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <span>Digite o codigo de ativação que aparece no seu dispositivo</span>
                    <Input
                      name="activationCode"
                      type="text"
                      placeholder="Ex: X0X0X0"
                      value={activationCode}
                      onChange={(e) => this.onChangeActivationCode(e)}
                    />
                  </FormGroup>                           
                </Col>           
              </Row>
              <AssignDeviceComponent
                devices={devices}
                onClick={this.goAssign}
              />
              <PaginationComponent
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={countItems}
                  onPageChange={this.onPageChange}
                />
            </div>
          </>
        )}
      </>
    );
  }
}

export default AssignDeviceContainer;
