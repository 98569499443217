import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

import DevicesComponent from "./DevicesComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'
import PaginationComponent from "../../components/PaginationComponent";
import { DEVICE_INFO_TAB } from "../../configs/constants";

@inject("DeviceStore", "OrganizationStore")
class DevicesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      devices: [],
    };
  }

  async componentDidMount() {
    this.getDevices();
  }

  getDevices = async (organization, organizationName, skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0;
    const { DeviceStore, OrganizationStore } = this.props;
    let organizations = await this.getOrganizations(organizationName);
    if (organization === undefined) organization = organizations[0];
    const data = await DeviceStore.getDevices(organization.organizationId,skip);
    let devicesAux = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.devices.length > 0) {
      devicesAux = data.devices;
      countItems = data.countItems;
      countPages = data.countPages;
    }  
    OrganizationStore.setOrganizationLogoUrl(organization.logoUrl);
    this.setState({
      organization: organization,
      organizations: organizations,
      devices: devicesAux,
      currentPage: currentPage,
      countItems: countItems,
      countPages: countPages,
      skip: skip,
      isLoading: false,
    });
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value);
    return organizationsData.organizations;
  };

  goDevice = async (deviceId) => {
    const { history } = this.props;
    history.push(`device?deviceId=${deviceId}&tab=${DEVICE_INFO_TAB}`);
  };

  goNewDevice = async () => {
    const { history, DeviceStore } = this.props;
    DeviceStore.setDeviceId(0);
    history.push(`new-device`);
  };

  onDelete = async (deviceId) => {
    this.setState({
      isLoading: true
    });

    const { DeviceStore } = this.props;
    const { organization, skip } = this.state;

    const result = await DeviceStore.delete(deviceId);
    if (result === "true" || result === true){
      AlertComponent.success("Dispositivo removido com sucesso");
      this.getDevices(organization, organization.name, skip);
    } else {
      AlertComponent.error("Falha ao remover dispositivo");
    }

    this.setState({
      isLoading: false
    });
  };

  onKeyUpOrganization = async (value) => {
    try {
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = (organizationName) => {
    let { organizations, organization, skip } = this.state;
    const { OrganizationStore } = this.props;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        OrganizationStore.setOrganizationLogoUrl(organization.logoUrl);
        break;
      }
    }

    this.getDevices(organization, organizationName, skip);
  };

  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 10;
    const { organization } = this.state;
    await this.getDevices(organization, organization.name, skip);
    this.setState({
      currentPage: currentPage,
      skip: skip,
      isLoading: false
    });
  };

  render() {
    const { isLoading, devices, organization, organizations, currentPage, countItems } = this.state;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <>
            <div className="content content-xs">
            <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <span style={{marginBottom: "0px"}}>Estabelecimento:</span>
                    <Autocomplete
                      noOptionsText={"Nenhum item"}
                      onKeyUp={(event, value) =>   
                        this.onKeyUpOrganization(document.getElementById("organization").value)
                      }                         
                      onChange={(event, value) =>                         
                        this.onChangeOrganization(value)
                      }
                      style={{paddingBottom: "10px"}}                                      
                      value={organization?.name}
                      id="organization"
                      options={organizations.map((organization) => organization.name)}
                      isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                      size="small"
                      renderInput={(params) => (
                        <TextField  {...params} variant='standard'/>
                      )}
                      sx={autoCompleteSx}
                      renderOption={(props, option) => {
                        return (
                          <span  {...props}>
                            {option}
                          </span>
                        );
                      }}                      
                    />
                    </FormGroup>
                  </Col>
              </Row>
              <Row>
                <Col style={{textAlign: "right"}}> 
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                    onClick={()=>this.goNewDevice()}
                  >
                    Novo dispositivo
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={2} md={1}>
                  Código
                </Col>
                <Col xs={3} md={4}>
                  Título
                </Col>
                <Col xs={4} md={5}>
                  Estabelecimento
                </Col>
              </Row>     
              <DevicesComponent
                devices={devices}
                onClick={this.goDevice}
                onDelete={this.onDelete}
              />
              <PaginationComponent
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={countItems}
                  onPageChange={this.onPageChange}
                />
            </div>
          </>
        )}
      </>
    );
  }
}

export default DevicesContainer;
