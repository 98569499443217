import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  FormGroup
} from "reactstrap";

import LoadingComponent from "../../components/LoadingComponent";
import ChargesComponent from "./ChargesComponent";
import PaginationComponent from "../../components/PaginationComponent";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat } from '../../helpers/utils'
import { CHARGE_STATUS } from '../../configs/constants'
import classNames from "classnames";

@inject("ChargeStore", "UserStore", "OrganizationStore")
class ChargesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      charges: [],
    };
  }

  async componentDidMount() {
    this.getCharges();
  }

  getCharges = async (organization, organizationName, skip, startDueDate, endDueDate, status) => {
    this.setState({
      isLoading: true,
    });

    let dtNow = new Date();
    if (skip === undefined) skip = 0;
    if (startDueDate === undefined) startDueDate = new Date(dtNow.getFullYear(),dtNow.getMonth(),1);
    if (endDueDate === undefined) 
    {
      endDueDate = new Date(dtNow.getFullYear(),dtNow.getMonth()+1,1);
      endDueDate.setDate(endDueDate.getDate()-1);
    }

    const { ChargeStore } = this.props;
    let { organizations } = this.state;   
    if (organization === undefined) {
      organizations = await this.getOrganizations(organizationName,0,true);
      organization = organizations[0];
    }  

    const data = await ChargeStore.get(organization.organizationId,dateFormat(startDueDate),dateFormat(endDueDate),status,skip);
    let charges = [];
    let total = 0;
    let currentPage = 1;
    let pages = 1;   
    if (data && data !== "" && data.charges && data.charges.length > 0) {
      charges = data.charges;
      total = data.total;
      pages = data.pages;
    } 

    this.setState({
      startDueDate,
      endDueDate,
      charges,
      organizations,
      organization,
      total,
      currentPage,
      pages,
      skip,
      isLoading: false,
    });
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value, 0, true);
    return organizationsData.organizations;
  };

  onKeyUpOrganization = async (value) => {
    try {
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = async (organizationName) => {
    let { startDueDate, endDueDate, organizations, organization, status, skip } = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }
    this.setState({
      organization: organization
    });

    await this.getCharges(organization, organizationName, skip, startDueDate, endDueDate, status);
  };

  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 10;
    const { startDueDate, endDueDate, organization, status } = this.state;
    await this.getCharges(organization, organization.name, skip, startDueDate, endDueDate, status);
    this.setState({
      currentPage: currentPage,
      isLoading: false,
      skip: skip
    });
  };

  onChangeStartDueDate = async (e) => {
    let { startDueDate, endDueDate, organization, skip, status } = this.state;
    startDueDate = e;
    this.setState({
      startDueDate
    });
    await this.getCharges(organization, organization.name, skip, startDueDate, endDueDate, status);
  };

  onChangeEndDueDate = async (e) => {
    let { startDueDate, endDueDate, organization, skip, status } = this.state;
    endDueDate = e;
    this.setState({
      startDueDate
    });
    await this.getCharges(organization, organization.name, skip, startDueDate, endDueDate, status);
  };

  onChangeStatus = async (newStatus) => {
    let { startDueDate, endDueDate, organization, skip, status } = this.state;
    status = newStatus;
    this.setState({
      status
    });
    await this.getCharges(organization, organization.name, skip, startDueDate, endDueDate, status);
  };

  render() {
    const { isLoading, charges, organizations, organization, currentPage, total, startDueDate, endDueDate, status } = this.state;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <>
          <div className="content content-xs">
          <Row>
            <Col xs={12} md={12}>
              <FormGroup>
                <span style={{marginBottom: "0px"}}>Estabelecimento:</span>
                <Autocomplete
                  noOptionsText={"Nenhum item"}
                  onKeyUp={(event, value) =>   
                    this.onKeyUpOrganization(document.getElementById("organization").value)
                  }                     
                  onChange={(event, value) =>                         
                    this.onChangeOrganization(value)
                  }
                  style={{paddingBottom: "10px"}}                                      
                  value={organization?.name}
                  id="organization"
                  options={organizations.map((organization) => organization.name)}
                  isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                  size="small"
                  renderInput={(params) => (
                    <TextField  {...params} variant='standard'/>
                  )}
                  sx={autoCompleteSx}
                  renderOption={(props, option) => {
                    return (
                      <span  {...props}>
                        {option}
                      </span>
                    );
                  }}                      
                />
              </FormGroup>
            </Col>    
            <Col xs={12} md={3} >
              <FormGroup>
                <span>Vencimento inicial</span>
                <DatePicker
                  id="startDueDate"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={startDueDate}
                  onChange={this.onChangeStartDueDate}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup>
                <span>Vencimento final</span>
                <DatePicker
                  id="endDueDate"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={endDueDate}
                  onChange={this.onChangeEndDueDate}
                />
              </FormGroup>
            </Col>     
            <Col xs={12} md={6}>
              <FormGroup>
                <span>Status</span><br/>
                <ButtonGroup style={{width: "100%"}}>
                  <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: status === undefined})} outline onClick={()=>this.onChangeStatus()} >Todos</Button>
                  <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: status === CHARGE_STATUS.pending})} outline onClick={()=>this.onChangeStatus(CHARGE_STATUS.pending)} >Pendente</Button>
                  <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: status === CHARGE_STATUS.received})} outline onClick={()=>this.onChangeStatus(CHARGE_STATUS.received)} >Pago</Button>
                  <Button style={{width: "25%"}} color="info" className={classNames("btn-simple", {active: status === CHARGE_STATUS.overdue})} outline onClick={()=>this.onChangeStatus(CHARGE_STATUS.overdue)} >Vencido</Button>
                </ButtonGroup>                        
              </FormGroup>
            </Col>             
          </Row>
            <Row>
              <Col xs={4} md={4}>
                Nome
              </Col>
              <Col xs={2} md={2}>
                Valor
              </Col>
              <Col xs={2} md={2}>
                Status
              </Col>
              <Col xs={2} md={2}>
                Vencimento
              </Col>
            </Row>
            <ChargesComponent
              charges={charges}
            />
            <PaginationComponent
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={total}
              onPageChange={this.onPageChange}
            />
          </div>         
          </>
        )}
      </>
    );
  }
}

export default ChargesContainer;
